import React, { useEffect, useState } from "react"
import SEO from "../seo"
import {
  URL_EMAIL,
  URL_HOTMART_CURSO_PRODUTIVIDADE,
  URL_HOTMART_EBOOK_PRODUTIVIDADE,
  URL_INSTA_FLYNOW,
  URL_WHATSAPP,
} from "../../constants"
import { getQueryParam, getTrackingHotmart } from "../../utils/query"
import { getTextCupom, formatToMoney } from "../../utils/cupom"
import styled from "styled-components"
import colors from "../../colors"
import { FaInstagram, FaWhatsapp } from "react-icons/fa"
import { MdMailOutline } from "react-icons/md"
import { Description, Row, TitleMain } from "../Ebooks"
import FAQ from "../FAQ"
import { isAfter, parseISO } from "date-fns"
const primary = colors.primary
const degradeBackground =
  "linear-gradient(297deg, rgba(170,32,255,1) 0%, rgba(115,87,225,1) 30%, rgba(32,26,56,1) 100%)"

const imageBackground = require("./background.jpg")
const ebookProdutividade = require("../../assets/images/ebookProdutividade.png")

export const H1 = styled.h1`
  text-align: center;
  font-size: 2.7rem;
`
export const Contact = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
`
const Space = styled.div`
  margin-top: 0.5rem;
`
export const ItemContact = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: white;
`

const Highlight = styled.span`
  color: ${({ color }) => color || primary};
`

export const H2 = styled.h2`
  font-size: 2rem;
  text-align: center;
  color: ${primary};
`

export const P = styled.p`
  font-size: 1.1rem;
  color: ${colors.regular};
`

const Button = styled.a`
  display: block;
  text-align: center;
  padding: 18px 50px;
  color: white;
  background: ${primary};
  border-radius: 40px;
  text-transform: uppercase;
  font-family: "Poppins-Bold";
  cursor: pointer;
  transition: all 0.4s linear;
  &:hover {
    opacity: 0.9;
  }
  width: 500px;
  text-decoration: none;
  @media (max-width: 600px) {
    width: 100%;
  }
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url(${imageBackground}) center center / cover no-repeat fixed;
  /* filter: blur(12px); */
  padding: 2rem;
  .video-copy {
    width: 680px;
    height: 340px;
    @media (max-width: 768px) {
      width: 360px;
      height: 180px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    width: 75%;
    justify-content: center;
    align-items: center;
    h1 {
      text-shadow: 3px 1px 9px rgba(0, 0, 0, 0.45);
    }
    @media (max-width: 600px) {
      width: 100%;
      h1 {
        font-size: 2rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
`

export const Section = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 15%;
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ background }) =>
    background && `background: ${background};`}

  @media (max-width: 768px) {
    padding: 2rem 10%;
    input {
      width: 100%;
    }
  }

  .icon {
    font-size: 10rem;
    color: ${colors.textStrong};
    cursor: pointer;
    transition: all 0.4s linear;
  }
  .icon:hover {
    color: ${colors.primary2};
  }
  input {
    padding: 15px;
    border: 5px solid ${colors.primary};
    border-radius: 12px;
    color: ${colors.primary};
    margin-bottom: 20px;
    font-size: 1.1rem;
    width: 80%;
    min-width: 280px;
    max-width: 97%;
  }
  input:focus {
    outline: none !important;
    border-color: ${colors.primary};
    box-shadow: 0 0 10px ${colors.primary};
  }

  .button-waiting-list {
    padding: 15px 50px;
    background: ${colors.green};
    border: none;
    border-radius: 15px;
    cursor: pointer;
    color: ${colors.white};
    font-size: 1.1rem;
    font-family: "Poppins-Bold";
  }
  .button-waiting-list:hover {
    transition: all 0.4s linear;
    box-shadow: 0 0 10px ${colors.green};
  }
  a {
    text-decoration: none;
  }
`

export const contact = [
  {
    link: URL_WHATSAPP,
    img: <FaWhatsapp className="icon" />,
    description: "31 999107753",
  },
  {
    link: URL_EMAIL,
    img: <MdMailOutline className="icon" />,
    description: "support@appflynow.com",
  },
  {
    link: URL_INSTA_FLYNOW,
    img: <FaInstagram className="icon" />,
    description: "@appflynow",
  },
]

const IconAndLabel = ({ icon, label }) => {
  return (
    <div
      style={{
        margin: 15,
        width: 300,
        padding: 25,
        borderRadius: 15,
        border: "1px solid #eee",
        boxShadow: "0 0 10px #ccc",
      }}
    >
      <H2>{icon}</H2>
      <P style={{ textAlign: "center" }}>{label}</P>
    </div>
  )
}

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin: 30px 0;
`

export default function ProdutividadeA() {
  const [cupom, setCupom] = useState(null)
  const [utm, setUtm] = useState("")

  function getUrlCheckout() {
    let url = URL_HOTMART_EBOOK_PRODUTIVIDADE
    if (cupom) {
      url = url.concat("?offDiscount=" + cupom)
    }
    if (utm) {
      const prefix = url.includes("?") ? "&" : "?"
      url = url.concat(prefix + "sck=" + getTrackingHotmart())
    }
    return url
  }


  const sendEvent = event => {
    if (window) {
      if (window.fbq) window.fbq("track", event)
      if (window.gtag) window.gtag("event", event)
    }
  }

  useEffect(() => {
    sendEvent("view_ebook_produtividade")
    setCupom(getQueryParam("cupom"))
    setUtm(getQueryParam("utm_source"))
  }, [])

  function getInfo() {
    const discontPercentage = cupom
      ? Number(cupom.replace(/\D/g, "") || "0")
      : 0
    const price = 39.99
    const newPrice = cupom
    ? formatToMoney(price * (1 - discontPercentage / 100))
    : formatToMoney(price)

    const newPriceInt = Number(newPrice.replace(',','.'))
    const ir = 1.08777194
    const qntInstallments = Math.floor((newPriceInt*ir)/8);
    const priceWithInteresting = newPriceInt * (1 + qntInstallments * 0.019)
    const instalmmentPrice =  priceWithInteresting / qntInstallments

    return {
      URL_CHECKOUT: getUrlCheckout(),
      oldPrice: "99,70",
      price: formatToMoney(price),
      newPrice,
      instalmmentPrice,
      textCupom: cupom
        ? getTextCupom(cupom) ||
          (discontPercentage
            ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
            : null)
        : null,
      discontPercentage,
      qntInstallments,
    }
  }
  const {
    URL_CHECKOUT,
    price,
    newPrice,
    oldPrice,
    textCupom,
    discontPercentage,
    instalmmentPrice,
    qntInstallments
  } = getInfo()

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }


  const onCheckout = () => {
    sendEvent("click_checkout_ebook_produtividade")
  }

  return (
    <>
      <SEO
        title="Guia Definitivo para a Produtividade! | EBOOK"
        pageId="ebook_produtividade"
        description="Se você sente que o seu dia deveria ter mais de 24 horas para dar conta de tudo, você PRECISA conhecer o nosso guia. Descubra como pequenos ajustes podem transformar a sua rotina, aumentar a sua produtividade e interromper de vez o ciclo de procrastinação constante."
        imageUrl={ebookProdutividade}
      />
      <Main>
        <div className="main-content">
          <TitleMain center color="#fff">
            Não, o problema <i><u>não</u></i> é a <Highlight>falta de tempo…</Highlight>🕗
          </TitleMain>
          <Description color="#ddd" style={{ marginBottom: 15 }}>
          Se você sente que o seu dia deveria ter <b>mais de 24 horas</b> para <b>dar conta de tudo</b>, você PRECISA conhecer o nosso guia. Descubra como pequenos ajustes podem <b>transformar a sua rotina, aumentar a sua produtividade</b> e interromper de vez o ciclo de <b>procrastinação constante</b>.
          </Description>
          {textCupom && (
            <h4
              style={{
                background: colors.red,
                color: "#fff",
                textAlign: "center",
                padding: 5,
                marginTop: 10,
                marginRight: 10,
                marginBottom: 20,
              }}
            >
              {textCupom}
            </h4>
          )}

          <img src={ebookProdutividade} height={'300'} />
          <br />
          <Button
            href={"#checkout"}
            style={{ marginTop: 15 }}
          >
            QUERO SER PRODUTIVO
          </Button>
        </div>
      </Main>

      <Section backgroundColor={colors.surface}>
        <H2>Você até tenta ser mais produtivo, mas…</H2>
        <br />
        <FlexWrap>
          <IconAndLabel
            icon={"🥵"}
            label={
              <>
                Nunca sabe por onde começar e vive <b>sobrecarregado</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🥱"}
            label={
              <>
              Acaba sempre caindo na armadilha da <b>procrastinação</b>
              </>
            }
          />
          <IconAndLabel
            icon={"😤"}
            label={
              <>
                Suas <b>listas de tarefas</b> só parecem crescer e nunca acabam
              </>
            }
          />
          <IconAndLabel
            icon={"🥵"}
            label={
              <>
                Seu <b>ambiente</b> te distrai e acaba com o seu foco
              </>
            }
          />
          <IconAndLabel
            icon={"😞"}
            label={
              <>
                Não consegue <b>manter a constância</b> e desiste muito facilmente
              </>
            }
          />
          <IconAndLabel
            icon={"😵‍💫"}
            label={
              <>
                Sente que o tempo está passando e você está no <b>mesmo lugar</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🥲"}
            label={
              <>
              Vê seus <b>sonhos e metas</b> cada vez mais distantes
              </>
            }
          />
        </FlexWrap>
        <P>
          Isso soa familiar? Não se preocupe, você não está sozinho! Muitos de nós já nos sentimos assim. Mas aqui está a verdade: existe uma <b>solução</b> para isso! 🚀 
        </P>
      </Section>

      <Section>
        <H2>Conheça o Guia Definitivo para a Produtividade</H2>
        <br />
        <P>
        Nosso guia foi feito para quem deseja <b>transformar sua rotina, aumentar sua produtividade</b> e alcançar <b>resultados reais</b> sem abrir mão da sua <b>qualidade de vida</b>.
        </P>
        <P>
        Este guia foi pensado para você que:
        </P>
        <FlexWrap>
          <IconAndLabel
            icon={"⌛️"}
            label={
              <>
              Quer finalmente ser <b>mais produtivo</b> e ter mais <b>tempo livre</b>
              </>
            }
          />
          <IconAndLabel
            icon={"😮‍💨"}
            label={
              <>
                Está cansado de <b>procrastinar</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🤩"}
            label={
              <>
                Deseja uma rotina <b>organizada, leve e eficaz</b>
              </>
            }
          />
          <IconAndLabel
            icon={"💪🏻"}
            label={
              <>
                Gostaria ter mais <b>constância</b> em seus projetos e na sua vida
              </>
            }
          />
          <IconAndLabel
            icon={"🚀"}
            label={
              <>
                Quer alcançar seus <b>sonhos</b> e construir uma <b>versão melhor</b> de si mesmo
              </>
            }
          />
        </FlexWrap>

        <P>
        Chega de <b>desperdiçar várias horas do seu dia</b> sem nem mesmo perceber e <b>comprometer seus maiores sonhos</b>. Dê um passo adiante agora mesmo antes que seja tarde demais! 
        😔
        </P>
        <br />
        <Button href={"#checkout"}>
          QUERO AUMENTAR MINHA PRODUTIVIDADE
        </Button>
        <br />
      </Section>

      <Section backgroundColor={colors.surface}>
        <H2>O que você vai receber?</H2>
        <br />
        <br />
        <img src={ebookProdutividade} height={400} />
        <br />
        <P>
        Ao adquirir o nosso guia, você terá acesso a:
        {/*<br /><br /> ✅ <b>23 técnicas</b> para aumentar sua produtividade;<br />
        ✅ <b>5 bônus exclusivos</b> com dicas extras para potencializar seus resultados;<br />
        ✅ <b>6 bônus exclusivos</b> em PDF;<br />
        ✅ Mais de <b>140 páginas de conteúdo transformador</b> para que você saia do lugar e comece a realizar seus sonhos. */}
        </P>
       
        <FlexWrap>
          <IconAndLabel
            icon={"🚀"}
            label={
              <>
                Elevar sua <b>produtividade</b>
                <br /> com <b>qualidade de vida</b>
              </>
            }
          />
          <IconAndLabel
            icon={"🎯"}
            label={
              <>
                <b>5 bônus exclusivos</b> com dicas extras para potencializar seus resultados
              </>
            }
          />
          <IconAndLabel
            icon={"🤩"}
            label={
              <>
                <b>6 bônus exclusivos</b> em PDF
              </>
            }
          />
          <IconAndLabel
            icon={"🧠"}
            label={
              <>
                Mais de <b>140 páginas de conteúdo transformador</b> para que você saia do lugar e comece a realizar seus sonhos
              </>
            }
          />
        </FlexWrap>

        <br />
      </Section>


      <Section id="checkout">
          <H2>O que torna este guia diferente?</H2>
          <br />
          <P>
          ✅ Melhores <b>técnicas</b> e <b>conhecimentos</b> sobre produtividade em <b>um só lugar</b>;<br />
          ✅ Evite <b>perder tempo</b> procurando respostas em <b>diversas fontes</b>;<br />
          ✅ Conteúdo para acessar <b>sempre que quiser</b>;<br />
          ✅ <b>Bônus exclusivos</b> para aprofundar ainda mais no tema.<br /><br />

          Por apenas <b><u>R$39,99</u></b> (<i>menos do que o preço de uma pizza</i>) você garante acesso ao nosso guia e começa sua jornada rumo a uma <b>vida mais produtiva</b>!
          </P>
          <br />
          <h3>
            <s>DE R$ {oldPrice}</s>
          </h3>
          {price !== newPrice && (
            <>
              <h2>POR <s>R$ {price}</s></h2>
              <h4 style={{ background: '#dd0000', color: '#fff', textAlign: 'center', margin: 5, padding: 5 }}>
                + {discontPercentage}% OFF
              </h4>
            </>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              border: "4px solid " + primary,
              alignItems: "center",
              color: primary,
              padding: 7,
              marginTop: 5
            }}
          >
            <h6>POR APENAS {qntInstallments}x DE </h6>
            <h1 style={{ margin: 10 }}> R$ {formatToMoney(instalmmentPrice)} </h1>
            <h6> ou {newPrice} à vista </h6>
          </div>
          <br />
        <Button href={URL_CHECKOUT} onClick={onCheckout}>QUERO MEU GUIA AGORA!</Button>

        <br />
      </Section>

      <Section backgroundColor={colors.surface}>
        <H2>Garantia incondicional de 7 dias ✅</H2>
        <br />
        <img
          src={require("../../assets/images/garantia7dias.png")}
          alt="garantia-7-dias"
          style={{ height: 250 }}
        />
        <div>
          <P>
          Nós confiamos tanto no poder transformador do nosso guia que oferecemos uma garantia incondicional de 7 dias. 
          </P>
          <P>
          Se você não estiver satisfeito(a), basta nos avisar dentro desse prazo e devolvemos seu dinheiro. Simples assim!
          </P>
        </div>
        <br />
      </Section>

      <Section>
        <H2>Alguma dúvida?</H2>
        <br />
        <P>Se tiver qualquer dúvida entre em contato conosco!</P>
        <Contact>
          {contact.map(i => (
            <ItemContact key={i.link}>
              <a
                href={i.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textAlign: "center" }}
              >
                {i.img}
                <h4 style={{ color: colors.textStrong }}>{i.description}</h4>
              </a>
            </ItemContact>
          ))}
        </Contact>
        <br />
      </Section>
    </>
  )
}
