import React, { useEffect } from 'react';
import '../assets/fonts/Poppins/Poppins-Light.ttf';
import '../assets/fonts/Poppins/Poppins-Bold.ttf';
import '../assets/fonts/Poppins/Poppins-Regular.ttf';
import "../components/layout.css";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import ProdutividadeA from '../components/Ebooks/ProdutividadeA';

const GuiaDefinitivoParaProdutividade = () => {
  useEffect(()=>{
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Ebook",
      // string - required - Type of interaction (e.g. 'play')
      action: "View Page Ebook",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "view-produtividade",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 'produtividade'
    })
  },[])
  return <ProdutividadeA/>
}
export default GuiaDefinitivoParaProdutividade